import React from "react";
import { useForm } from "react-hook-form";
import { useState } from 'react';

export default function App() {
  const { register, handleSubmit, errors, reset, setError } = useForm();
  const { formState: {isSubmitting}} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const GATEWAY_URL = process.env.CONTACTFORM_API_URL;
  const onSubmit = data => {
  try {
    fetch(GATEWAY_URL, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    reset();
    setSubmitted(true);
  } catch (error) {
    setError('submit', 'submitError', `Doh! ${error.message}`);
  }
};

const showThankYou = (<div>Thank you for your message</div>);
const showForm = <form />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div class="col-lg-12">
	      <div class="col-md-4">
		      	<label htmlFor="name">
		      		<h3 class="form-label margin-top-md ">Name</h3>
		      	</label>
	      </div>
	      <div class="col-md-6">
		        <input
		          disabled={isSubmitting}	
		          type="text"
		          class="form-control col-md-10 col-sm-12 col-xs-12"
		          name="name"
		          id="name"
		          placeholder="Enter your name"
		          ref={register({ required: true, maxLength: 30 })}
		        />	      
	      </div>
      </div>
      <div class="col-lg-12">
	      <div class="col-md-4">
		       <label htmlFor="phone">
		       	<h3 class="form-label margin-top-md ">Phone</h3>
		       </label>
	      </div>
	      <div class="col-md-6">
		        <input
		          disabled={isSubmitting}
		          type="text"
		          class="form-control col-md-10 col-sm-12 col-xs-12"
		          name="phone"
		          id="phone"
		          placeholder="Enter your phone"
		          ref={register({ required: true, maxLength: 30 })}
		        />
	      </div>
	  </div>
	  <div class="col-lg-12">
	      <div class="col-md-4">
		      <label htmlFor="email">
		      	<h3 class="form-label">Email</h3>
		      </label>
	      </div>
	      <div class="col-md-6">
		        <input
		          disabled={isSubmitting}	
		          type="email"
		          class="form-control col-md-10 col-sm-12 col-xs-12"
		          name="email"
		          id="email"
		          placeholder="your@email.address"
		          ref={register({required: 'Email is required',})}
		        />
		    </div>
		</div>
	    <div class="col-lg-12">
		  <div class="col-md-4">
			      <label htmlFor="desc">
			      	<h3 class="form-label">Question</h3>
			      </label>
		  </div>
		  <div class="col-md-6">
	        <textarea
	          ref={register}
	          class="form-control col-md-10 col-sm-12 col-xs-12"
	          name="desc"
	          id="desc"
	          rows="3"
	          placeholder="Your message"
	        />
	      </div>
      </div>
      
      <div class="col-lg-12">
      <div class="col-md-4">
      </div>
      <div class="col-md-4">      
	      {/* use aria-invalid to indicate field contain error */}
	      {/* use role="alert" to announce the error message */}
	      {errors.name && errors.name.type === "required" && (
	        <span role="alert">This is required</span>
	      )}
	      {errors.name && errors.name.type === "maxLength" && (
	        <span role="alert">Max length exceeded</span>
	      )}
		  &nbsp;
	      <input 
	      disabled={isSubmitting} 
	      type="submit" 
	      className="block w-full mb-10 mt-8 border-2 rounded px-6 py-1 border-gray-500 bg-green-500 hover:bg-green-700 text-white"
	      value="Send Message"
	      />
	      {submitted ? showThankYou : showForm}
      </div>
      </div>
    </form>
  );
}